import React, { useCallback, useEffect, useRef, useState } from 'react'
import Form, { Input } from '../../components/Form'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import Modal from '../../components/Modal'
import logo from '../../assets/media/logos/logo-principal.png'
import { useLoading } from 'hooks/loading'
import { Loading } from 'components/Loading'

interface SingInFormData {
  email: string
  password: string
}

const SignIn: React.FC = () => {
  const refModal = useRef(null)
  const { signIn, backupHistory, setHistory } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const [modalEdit, setModalEdit] = useState(false)

  const { activeLoading, disableLoading, loading } = useLoading()

  const onSubmit = useCallback(
    async (data: SingInFormData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password
        })
        const a = document.createElement('a')
        a.href = backupHistory || '/dashboard'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setHistory('')
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Confira seus dados de acesso.'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signIn, addToast, history]
  )

  const handleClickOnClose = () => {
    setModalEdit(false)
  }

  const handleForgotPassword = useCallback(
    async ({ email }) => {
      activeLoading()
      try {
        await api.post('/password/forgot/Client', {
          email
        })
        addToast({
          type: 'success',
          title: 'Email enviado com sucesso',
          description: 'Email de renovação de senha enviado.'
        })
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao enviar email',
          description: 'Erro na tentativa de enviar o email.'
        })
      }
      disableLoading()
      handleClickOnClose()
    },
    [activeLoading, addToast, disableLoading]
  )

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={logo}
              style={{
                maxWidth: '100%',
                height: 'auto',
                padding: '10px',
                maxHeight: '150px'
              }}
            />
          </a>
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Form className="form w-100" onSubmit={onSubmit}>
              <div className="fv-row">
                <Input
                  autoComplete="off"
                  label="Email"
                  name="email"
                  rules={{ required: true }}
                />
              </div>

              <div className="fv-row mb-10">
                <Input
                  className=" placeholder-no-fix"
                  type="password"
                  autoComplete="off"
                  label="Senha"
                  name="password"
                  rules={{ required: true }}
                />
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  <span className="indicator-label">Continuar</span>
                </button>
                <a href="#" onClick={() => setModalEdit(true)}>
                  Esqueceu a senha
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Esqueceu a senha'}
        Children={
          <>
            <div>
              <p className="fs-6 py-2">
                Para redefinir sua senha preencha o campo abaixo com seu e-mail
              </p>
              <Form classname="form w-100" onSubmit={handleForgotPassword}>
                <div className="fv-row">
                  <Input
                    autoComplete="off"
                    placeholder="E-mail"
                    label="E-mail"
                    name="email"
                    rules={{ required: true }}
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                  >
                    <span className="indicator-label">Continuar</span>
                  </button>
                </div>
              </Form>
            </div>
          </>
        }
      />
      {loading && <Loading isActive={loading} />}
    </>
  )
}

export default SignIn
