import styled from 'styled-components'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`

export const StyledLink = styled.a`
  color: #009ef7;
  cursor: pointer;

  &:hover {
    color: '0.2s ease, background-color 0.2s ease';
  }
`

export const ModContainer = styled.div`
  .fc-view-harness {
    min-height: 400px;
    overflow: scroll;
    .fc-view {
      min-width: 600px;
    }
  }
`
