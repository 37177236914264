import { useLoading } from 'hooks/loading'
import { useToast } from 'hooks/toast'
import { useForm } from 'react-hook-form'
import { TextAreaContainer, ModalContainer } from './styles'
import { useEffect, useState } from 'react'

interface IChooseItemsForm {
  menuDishes: ImenuDishes[]
  selectedDishes: ISelectedDish[]
  selectedLabelMessage?: string
  hasMessage: boolean
  setSelectedDishes: React.Dispatch<React.SetStateAction<ISelectedDish[]>>
  handleClickOnSuccess: (
    selected: ISelectedDish[],
    labelMessage?: string
  ) => void
}

interface ImenuDishes {
  dish_type_id: number
  dish_type_name: string
  dish_type_options: number
  dishes: {
    id: number
    dish_amount: number
    name: string
    is_default_dish: boolean
  }[]
}

const ChooseItemsForm = ({
  menuDishes,
  selectedDishes,
  selectedLabelMessage,
  hasMessage,
  setSelectedDishes,
  handleClickOnSuccess
}: IChooseItemsForm) => {
  const { register, handleSubmit } = useForm()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [labelMessage, setLabelMessage] = useState<string>('')

  const emojis = [
    '😀',
    '😄',
    '😁',
    '😆',
    '🤤',
    '💪',
    '❤️',
    '👧',
    '👦',
    '💕',
    '🐶',
    '🦣',
    '🐷',
    '🍀',
    '🍇',
    '🍉',
    '🍎',
    '🥝',
    '🍩',
    '🧋',
    '🍔',
    '🍞',
    '🥬',
    '🍽️',
    '🎉',
    '🎁',
    '🥇',
    '🧸',
    '⚽'
  ]

  useEffect(() => {
    setLabelMessage(selectedLabelMessage)
  }, [selectedLabelMessage])

  const onSuccessSubmit = async () => {
    console.log(menuDishes)
    console.log(selectedDishes)
    const dishes = menuDishes.map(md => md.dish_type_name)
    let dishNotFound = false
    dishes.forEach(dish => {
      const hasDish = selectedDishes.find(sd => sd.dish_type_name === dish)
      if (!hasDish) {
        dishNotFound = true
      }
    })

    if (dishNotFound) {
      addToast({
        type: 'error',
        title: 'Selecione um item para cada categoria'
      })
      return
    }

    let dishExtraSelected = false

    dishes.forEach(dish => {
      const hasDish = selectedDishes.find(sd => sd.dish_type_name === dish)
      const selectedByDish = selectedDishes.filter(
        sd => sd.dish_type_name === dish
      )
      if (hasDish.dish_type_options < selectedByDish.length) {
        dishExtraSelected = true
      }
    })

    if (dishExtraSelected) {
      addToast({
        type: 'error',
        title: 'Selecione um item para cada categoria'
      })
      return
    }

    try {
      activeLoading()
      await handleClickOnSuccess(selectedDishes, labelMessage)
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }
  return (
    <ModalContainer>
      <form
        onSubmit={handleSubmit(onSuccessSubmit)}
        className="d-flex gap-4 flex-column"
      >
        {menuDishes.map(dishByType => {
          return (
            <div
              key={dishByType.dish_type_id}
              className="d-flex gap-2 flex-column"
            >
              <h3 className="fs-5">
                {dishByType.dish_type_name}{' '}
                {Number(dishByType.dish_type_options) > 1 ? (
                  <span> (Selecione até {dishByType.dish_type_options})</span>
                ) : (
                  ''
                )}
              </h3>
              {dishByType.dishes.map(dish => {
                return (
                  <div key={dish.id} className="d-flex gap-3">
                    <input
                      id={`dish_${dish.id}`}
                      type="checkbox"
                      {...register(dishByType.dish_type_name)}
                      name={dishByType.dish_type_name}
                      value={dish.id}
                      onChange={e => {
                        if (e.target.checked) {
                          const countByType = selectedDishes.filter(
                            sd =>
                              sd.dish_type_name === dishByType.dish_type_name
                          ).length
                          if (
                            Number(dishByType.dish_type_options) <= countByType
                          ) {
                            setSelectedDishes(prev => {
                              const copy = [...prev]
                              const old = copy.filter(
                                c =>
                                  c.dish_type_name === dishByType.dish_type_name
                              )
                              if (old.length) {
                                old.forEach(o => {
                                  const index = copy.findIndex(
                                    c => c.dish_id === o.dish_id
                                  )
                                  if (index !== -1) {
                                    copy.splice(index, 1)
                                  }
                                })
                              }
                              return copy
                            })
                          }
                          setSelectedDishes(prev => {
                            const copy = [...prev]
                            const selected = {
                              dish_id: dish.id,
                              dish_amount: dish.dish_amount,
                              is_default_dish: dish.is_default_dish,
                              dish_name: dish.name,
                              dish_type_name: dishByType.dish_type_name,
                              dish_type_id: dishByType.dish_type_id,
                              dish_type_options: dishByType.dish_type_options
                            }
                            return [...copy, selected]
                          })
                        } else {
                          setSelectedDishes(prev => {
                            const copy = [...prev]
                            const index = copy.findIndex(
                              c => c.dish_id === dish.id
                            )
                            if (index !== -1) {
                              copy.splice(index, 1)
                            }
                            return copy
                          })
                        }
                      }}
                      checked={selectedDishes.some(
                        sd => sd.dish_id === dish.id
                      )}
                    />
                    <label htmlFor={`dish_${dish.id}`}>
                      {dish.name}
                      {dish.is_default_dish}
                    </label>
                  </div>
                )
              })}
            </div>
          )
        })}
        {hasMessage && (
          <TextAreaContainer>
            <label htmlFor="label_message">
              Mensagem para seu(sua) filho(a)
            </label>
            <div className="emoji-container">
              {emojis.map((emoji, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (labelMessage.length < 80) {
                      setLabelMessage(labelMessage + emoji)
                    }
                  }}
                >
                  {emoji}
                </div>
              ))}
            </div>
            <textarea
              id="label_message"
              name="label_message"
              rows={2}
              maxLength={80}
              onChange={({ target }) => setLabelMessage(target.value)}
              value={labelMessage}
            ></textarea>
          </TextAreaContainer>
        )}
        <button className="btn btn-sm btn-primary float-end">Confirmar</button>
      </form>
    </ModalContainer>
  )
}

export default ChooseItemsForm
