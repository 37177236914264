import styled from 'styled-components'

export const TextAreaContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;

  .emoji-container {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 2px;

    font-size: 14px;
    div {
      cursor: pointer;
    }
  }
`

export const ModalContainer = styled.div`
  height: 60vh;

  button {
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    padding: 10px;
  }
`
