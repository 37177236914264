import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction' // needed for dayClick
import { useParams } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { useState, useCallback, useEffect } from 'react'
import api from 'services/api'
import moment from 'moment'
import Modal from 'components/Modal'
import ChooseItemsForm from '../components/ChooseItemsForm'
import { useLoading } from 'hooks/loading'
import { ModContainer } from './styles'
import { PageTitle } from 'assets/layout/core'

const MenuFoodView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const [contract, setContract] = useState<IContract>()
  const [hasItems, setHasItems] = useState(false)
  const [events, setEvents] = useState([])
  const [items, setItems] = useState<any>({})
  const [menuDishes, setMenuDishes] = useState([])
  const [studentDishes, setStudentDishes] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedDishes, setSelectedDishes] = useState([])
  const [labelMessage, setLabelMessage] = useState<string>('')
  const [hasMessage, setHasMessage] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [hasPendingPayments, setHasPendingPayments] = useState(true)

  const [dishesTypes, setDishesTypes] = useState<any[]>()

  const { activeLoading, disableLoading } = useLoading()
  const { user } = useAuth()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('commercial/dishesTypes')
      console.log(data)
      setDishesTypes(data)
    })()
  }, [])

  const getHasPendingPayments = useCallback(async (contractId: number) => {
    if (!contractId) {
      return
    }
    const { data } = await api.post('commercial/contracts/hasPendingPayments', {
      contractId: contractId,
      days: 6
    })
    setHasPendingPayments(data)
  }, [])

  const getStudentDishes = useCallback(async (contract: IContract) => {
    const getStudentDishesResponse = await api.post(
      'commercial/menuFoodDishesStudents/findbyparams',
      {
        menu_food_id: contract?.contract_plans[0]?.plan_frequency?.menu_food.id,
        contract_id: contract?.id,
        contract_plan_id: contract?.contract_plans[0]?.id,
        student_id: contract?.student_id,
        institution_id: contract?.institution_id
      }
    )
    const events: any[] = []
    setStudentDishes(getStudentDishesResponse.data)
    getStudentDishesResponse.data.forEach((dish: any) => {
      const dishDate = dish.date.substring(0, 10).split('/').reverse().join('-')
      const dishes = dish.dishes_info
      dishes.forEach((food: any) => {
        events.push({
          title: food.dish_name,
          date: dishDate
        })
      })
    })
    setEvents(events)
  }, [])

  const populateCalendar = useCallback(
    async (contract: IContract, hasItems: boolean) => {
      if (!dishesTypes) {
        return
      }
      let menuFood = contract.contract_plans[0].plan_frequency.menu_food

      if (menuFood.items_parent_id) {
        const { data } = await api.get(
          `commercial/menuFoods/view/${menuFood.items_parent_id}`
        )
        menuFood = data
      }

      const hasMessage =
        menuFood.menu_food_type.name.toLowerCase().includes('almoço') === false

      setHasMessage(hasMessage)

      const daysOfWeek = JSON.parse(
        contract.contract_plans[0].frequency_days_of_week
      )?.frequency_days_of_week
      const studentDays = daysOfWeek.map((d: any) => {
        if (d === 'Segunda-feira') {
          return 1
        }
        if (d === 'Terça-feira') {
          return 2
        }
        if (d === 'Quarta-feira') {
          return 3
        }
        if (d === 'Quinta-feira') {
          return 4
        }
        if (d === 'Sexta-feira') {
          return 5
        }
        return 0
      })
      const events: any[] = []
      const items: any = {}
      menuFood.menu_dishes?.forEach(dish => {
        const dishDate = dish.date
          .substring(0, 10)
          .split('/')
          .reverse()
          .join('-')
        const day = moment(dishDate, 'YYYY-MM-DD').weekday()
        if (studentDays.includes(day)) {
          const dishes = JSON.parse(dish.dishes_info)
          if (dishes) {
            dishes.forEach((food: any) => {
              const options = dishesTypes?.find(
                dt => dt.id === food.dish_type_id
              )?.options_quantity
              events.push({
                title: food.dish_name,
                date: dishDate
              })
              if (!items[dishDate]) {
                items[dishDate] = {}
              }
              if (!items[dishDate][food.dish_type_name]) {
                items[dishDate][food.dish_type_name] = {}
              }
              if (!items[dishDate][food.dish_type_name].dishes) {
                items[dishDate][food.dish_type_name].dishes = []
              }
              items[dishDate][food.dish_type_name] = {
                dish_type_id: food.dish_type_id,
                dish_type_name: food.dish_type_name,
                dish_type_options: options,
                dishes: [
                  ...items[dishDate][food.dish_type_name].dishes,
                  {
                    id: food.dish_id,
                    name: food.dish_name,
                    dish_amount: food.dish_amount,
                    is_default_dish: food.is_default_dish
                  }
                ]
              }
            })
          }
        }
      })
      if (!hasItems) {
        setEvents(events)
      } else {
        await getStudentDishes(contract)
        setItems(items)
      }
    },
    [dishesTypes, getStudentDishes]
  )

  const getContracts = useCallback(async () => {
    const contractsListResponse = await api.get<IContract[]>(
      `commercial/contracts/byClient/${user.id}`
    )
    const contract1 = contractsListResponse.data?.find(contractResponse =>
      contractResponse.contract_plans.some(plan => String(plan.id) === id)
    )
    const contractWithPlan = {
      ...contract1,
      contract_plans: [
        contract1.contract_plans.find(plan => String(plan.id) === id)
      ]
    }
    setContract(contractWithPlan)
    getHasPendingPayments(contractWithPlan.id)
    if (contractWithPlan?.contract_plans[0]?.plan_frequency?.menu_food) {
      const hasItems =
        contractWithPlan.contract_plans[0].plan_frequency.menu_food.has_items
      setHasItems(hasItems)
      try {
        activeLoading()
        await populateCalendar(contractWithPlan, hasItems)
        disableLoading()
      } catch (error) {
        disableLoading()
      }
    }
  }, [
    activeLoading,
    disableLoading,
    getHasPendingPayments,
    id,
    populateCalendar,
    user.id
  ])

  useEffect(() => {
    getContracts()
  }, [getContracts])

  const handleDateClick = (click: DateClickArg) => {
    // if (hasPendingPayments) {
    //   return
    // }
    if (hasItems) {
      const date = click.dateStr
      setSelectedDate(date)
      if (items[date]) {
        const limitDate = moment().add(3, 'd')
        if (
          moment(date, 'YYYY-MM-DD').isBefore(limitDate) ||
          moment(date, 'YYYY-MM-DD').isBefore(
            moment(contract?.start_date, 'DD/MM/YYYY HH:mm:ss'),
            'D'
          )
        ) {
          return
        }
        const dishesByType = Object.entries(items[date]).map(
          (type: any) => type[1]
        )
        setMenuDishes(dishesByType)
        const studentDish = studentDishes.find(
          studentDishe =>
            studentDishe.date
              .substring(0, 10)
              .split('/')
              .reverse()
              .join('-') === date
        )
        if (studentDish) {
          setSelectedDishes(studentDish.dishes_info)
          setLabelMessage(studentDish.label_message || '')
        }

        setModalOpen(true)
      }
    }
  }
  const handleSuccessModal = async (data: any, labelMessage: string) => {
    let method: 'POST' | 'PUT' = 'POST'
    let url = '/commercial/menuFoodDishesStudents/create'
    const studentDish = studentDishes.find(
      studentDishe =>
        studentDishe.date.substring(0, 10).split('/').reverse().join('-') ===
        selectedDate
    )?.id
    if (studentDish) {
      method = 'PUT'
      url = `/commercial/menuFoodDishesStudents/update/${studentDish}`
    }
    await api.request({
      method,
      url,
      data: {
        menu_food_id: contract?.contract_plans[0]?.plan_frequency?.menu_food.id,
        contract_id: contract.id,
        contract_plan_id: contract?.contract_plans[0]?.id,
        student_id: contract.student_id,
        institution_id: contract.institution_id,
        date: selectedDate,
        dishes_info: data,
        label_message: labelMessage
      }
    })
    await getStudentDishes(contract)
    setSelectedDishes([])
    setLabelMessage('')
    setModalOpen(false)
  }
  const hasItemsInCell = (date: Date) => {
    return (
      hasItems &&
      items[moment(date, 'YYYY-MM-DD').add(3, 'h').format('YYYY-MM-DD')] &&
      moment(date, 'YYYY-MM-DD').isAfter(moment().add(3, 'd')) &&
      moment(date, 'YYYY-MM-DD')
        .add(3, 'h')
        .isSameOrAfter(moment(contract?.start_date, 'DD/MM/YYYY HH:mm:ss'), 'D')
    )
  }
  const hasItemsSelectedInCell = (date: Date) => {
    return events.some(
      event =>
        event.date ===
        moment(date, 'YYYY-MM-DD').add(3, 'h').format('YYYY-MM-DD')
    )
  }
  return (
    <>
      <ModContainer className="container-fluid">
        <PageTitle>Cardápios</PageTitle>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body p-9 calendar-wrapper">
            <div className="calendar-wrapper-inner">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                timeZone="-03:00"
                locale="pt"
                events={events}
                dayCellContent={a => (
                  <>
                    {hasItemsInCell(a.date) && (
                      <button
                        type="button"
                        className="btn"
                        style={{
                          marginRight: '0px',
                          background: '#f2f2f2',
                          padding: '3px 10px'
                        }}
                      >
                        {hasItemsSelectedInCell(a.date)
                          ? 'Editar'
                          : 'Selecione'}
                      </button>
                    )}
                    <b>{a.dayNumberText}</b>
                  </>
                )}
                eventContent={info => (
                  <span style={{ whiteSpace: 'normal', margin: '5px 10px' }}>
                    {info.event.title}
                  </span>
                )}
                headerToolbar={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'dayGridWeek,dayGridMonth'
                }}
                dateClick={handleDateClick}
              />
            </div>
          </div>
        </div>
      </ModContainer>
      <Modal
        onClickButtonCancel={() => {
          setSelectedDishes([])
          setLabelMessage('')
          setModalOpen(false)
        }}
        isOpenModal={modalOpen}
        pageTitle={'Escolha os itens para o dia selecionado'}
        style={{
          padding: '5%'
        }}
        Children={
          <ChooseItemsForm
            menuDishes={menuDishes}
            setSelectedDishes={setSelectedDishes}
            selectedDishes={selectedDishes}
            selectedLabelMessage={labelMessage}
            hasMessage={hasMessage}
            handleClickOnSuccess={handleSuccessModal}
          />
        }
      />
    </>
  )
}

export default MenuFoodView
