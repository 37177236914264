import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { Loading } from 'components/Loading'
import { useLoading } from 'hooks/loading'

export const ForgotPassword: React.FC = () => {
  const [token] = useQueryParam('token', StringParam)
  const { addToast } = useToast()
  const { activeLoading, disableLoading, loading } = useLoading()
  const history = useHistory()

  const onSubmit = useCallback(
    async () => {
      activeLoading()
      try {
        await api.post('password/resetCpf/Client', {
          token
        })
        addToast({
          type: 'success',
          title: 'Senha alterada',
          description: 'Sua senha foi alterada com sucesso.'
        })
        disableLoading()
        history.push('/')
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao altera a senha',
          description: 'Não foi possivel alterar a senha.'
        })
      }
      disableLoading()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, history]
  )
  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          {/* <img
            alt="Logo"
            src={logoDark}
            className="h-150px w-400px"
            style={{ objectFit: 'cover' }}
          /> */}
        </a>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <p className="mb-4">
            Ao resetar sua senha, você receberá uma mensagem no whatsapp com sua
            nova senha
          </p>
          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
              onClick={onSubmit}
            >
              <span className="indicator-label">Resetar senha</span>
            </button>
          </div>
        </div>
      </div>
      {loading && <Loading isActive={loading} />}
    </div>
  )
}
